<template>
  <span :class="$style.root">
    <BaseDateTimeRangeComponent
      v-if="dateStart && dateEnd"
      :date-start="toDate(dateStart)"
      :date-end="toDate(dateEnd)"
      :format="format"
      :locale="locale"
    />
    <BaseDateTimeComponent
      v-else-if="dateStart"
      :date="toDate(dateStart)"
      :format="format"
      :locale="locale"
    />
  </span>
</template>

<script setup>
import locale_nl from 'dayjs/locale/nl'
import locale_en from 'dayjs/locale/en'

const { locale: currentLocale, locales } = useI18n()

const props = defineProps({
  dateStart: {
    type: [Date, String],
    required: true
  },
  dateEnd: {
    type: [Date, String],
    required: false
  },
  format: {
    type: String,
    required: false,
    default: 'D MMM YYYY, HH:mm'
  }
})

const toDate = (date) => {
  return dayJs(date).toDate()
}

const locale = computed(() => {
  if (currentLocale.value === 'nl') {
    return locale_nl
  } else {
    return locale_en
  }
})
</script>

<style module lang="scss">
.root {}

.root :global(.same-year-month-day-hour-minute) {
  :global(.to) {
    display: none;
  }

  [class*='seperator'] {
    display: none;
  }
}

.root :global(.same-year-month-day) {
  :global(.to) {
    :global(.day),
    :global(.day)+ :global(.sep),

    :global(.month),
    :global(.month)+ :global(.sep),

    :global(.year),
    :global(.year)+ :global(.sep) {
      display: none;
    }
  }

  &:has([class*='date to']) {
    :global([class*='seperator']) {
      display: none;
    }
  }
}

.root :global(.same-year-month) {
  :global(.from) {
    :global(.year),
    :global(.year)+ :global(.sep) {
      display: none;
    }

    &:has(:global(.hour)) {
      :global(.month) + :global(.sep) {
        display: none;
      }

      :global(.year)+ :global(.sep) {
        display: initial;
      }
    }
  }
}

.root :global(.same-year) {
  :global(.from) {

    :global(.year),
    :global(.year)+ :global(.sep) {
      display: none;
    }
  }
}
</style>
